import { useEffect } from 'react';
import * as React from 'react';
import useIsSsr from "./useIsSsr"

export const useIsVisible = () => {
    const isSsr = useIsSsr();

    const [isVisible, setIsVisible] = React.useState(isSsr ? false : !window.document.hidden);

    useEffect(() => {
        if (isSsr) return;

        const handler = () => {
            setIsVisible(!window.document.hidden)
        }

        window.document.addEventListener('visibilitychange', handler)

        return () => window.document.removeEventListener('visibilitychange', handler);
    }, [isSsr])

    return isVisible;
}