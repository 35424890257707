import * as React from "react";

export type Config = {
  optionsPerMonth: number;
  avgStrike: number;
};

export const ConfigForm: React.FC<{
  initialConfig: Config;
  onConfigChanged: (newConfig: Config) => unknown;
}> = ({ initialConfig, onConfigChanged }) => {
  const [config, setConfig] = React.useState(initialConfig);

  const handleSubmit = () => {
    onConfigChanged(config);
  };

  return (
    <form onSubmit={handleSubmit}>
      <p>
        <label>
          Options per month:
          <input
            type="number"
            value={config.optionsPerMonth}
            step={1}
            onChange={(e) => {
              try {
                const optionsPerMonth = e.target.valueAsNumber;
                setConfig((config) => ({
                  ...config,
                  optionsPerMonth,
                }));
              } catch {}
            }}
          />
        </label>
      </p>
      <p>
        <label>
          Average strike:
          <input
            type="number"
            value={config.avgStrike}
            step={0.01}
            onChange={(e) =>
              setConfig((config) => ({
                ...config,
                avgStrike: e.target.valueAsNumber,
              }))
            }
          />
        </label>
      </p>
      <button type="submit" style={{ width: "auto" }}>
        Save
      </button>
    </form>
  );
};
