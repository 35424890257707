import * as React from "react";
import useSWR from "swr";
import Layout from "../../components/Layout";
import { useIsVisible } from "../../hooks/useIsVisible";
import { ConfigForm } from "../../components/vesty/ConfigForm";

type Config = {
  optionsPerMonth: number;
  avgStrike: number;
};

const QUOTE_URL = "/.netlify/functions/getQuote";

export type QUOTE_RESULT = {
  last: number;
  source: "STOCK" | "FTX";
};

const fetcher = (url: string) => fetch(url).then((r) => r.json());

const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
});

const WORKING_MS_PER_MONTH = 20 * 8 * 60 * 60 * 1000;
const DAYS_PER_MONTH = 30.4;
const MS_PER_HOUR = 1000 * 60 * 60;
const MS_PER_MONTH = MS_PER_HOUR * 24 * DAYS_PER_MONTH;

const IncomePerMonth: React.FC<{ price: number; config: Config }> = ({
  price,
  config: { avgStrike, optionsPerMonth },
}) => <>{currencyFormatter.format((price - avgStrike) * optionsPerMonth)}</>;

const IncomePerDay: React.FC<{ price: number; config: Config }> = ({
  price,
  config: { avgStrike, optionsPerMonth },
}) => (
  <>
    {currencyFormatter.format(
      ((price - avgStrike) * optionsPerMonth) / DAYS_PER_MONTH
    )}
  </>
);

const IncomePerHour: React.FC<{ price: number; config: Config }> = ({
  price,
  config: { avgStrike, optionsPerMonth },
}) => (
  <>
    {currencyFormatter.format(
      ((price - avgStrike) * optionsPerMonth) / DAYS_PER_MONTH / 24
    )}
  </>
);

const IncomePerWorkingHour: React.FC<{ price: number; config: Config }> = ({
  price,
  config: { avgStrike, optionsPerMonth },
}) => (
  <>
    {currencyFormatter.format(
      ((price - avgStrike) * optionsPerMonth) / DAYS_PER_MONTH / 8
    )}
  </>
);

const IncomeSincePageOpen: React.FC<{ price: number; config: Config }> = ({
  price,
  config: { avgStrike, optionsPerMonth },
}) => {
  const [loadTime] = React.useState(Date.now());
  const [updateTime, setUpdateTime] = React.useState(Date.now());

  const isVisible = useIsVisible();
  React.useEffect(
    function updateTimespan() {
      if (!isVisible) return;

      const id = setInterval(() => {
        setUpdateTime(Date.now());
      }, 100);

      return () => clearInterval(id);
    },
    [isVisible]
  );

  const optionsPerMs = optionsPerMonth / MS_PER_MONTH;

  return (
    <>
      {currencyFormatter.format(
        (price - avgStrike) * optionsPerMs * (updateTime - loadTime)
      )}
    </>
  );
};

export const VestyIndex: React.FC = () => {
  const { data } = useSWR<QUOTE_RESULT>(QUOTE_URL, fetcher, {
    refreshInterval: 1000 * 60,
  });

  const price = data?.last;
  const [config, setConfig] = React.useState<Config | undefined>(undefined);
  const [isEditingConfig, setIsEditingConfig] = React.useState(false);

  React.useEffect(function loadInitialConfig() {
    const storedConfig = localStorage.getItem("vesty_config");
    if (storedConfig) {
      setConfig(JSON.parse(storedConfig));
    } else {
      setIsEditingConfig(true);
    }
  }, []);

  if (isEditingConfig) {
    return (
      <Layout>
        <div style={{ marginTop: 20 }}>
          <ConfigForm
            initialConfig={config ?? { optionsPerMonth: 10, avgStrike: 18 }}
            onConfigChanged={(newConfig) => {
              setConfig(newConfig);
              localStorage.setItem("vesty_config", JSON.stringify(newConfig));
              setIsEditingConfig(false);
            }}
          />
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <div style={{ marginTop: 20 }}>
        <p>
          (Some stock) is currently trading at{" "}
          {data?.last ? currencyFormatter.format(data?.last) : "?"} (
          {data?.source})
        </p>
        <table>
          <tr>
            <td>Monthly</td>
            <td>
              {config && price ? (
                <IncomePerMonth config={config} price={price} />
              ) : null}
            </td>
          </tr>
          <tr>
            <td>Daily</td>
            <td>
              {config && price ? (
                <IncomePerDay config={config} price={price} />
              ) : null}
            </td>
          </tr>
          <tr>
            <td>Hourly</td>
            <td>
              {config && price ? (
                <IncomePerHour config={config} price={price} />
              ) : null}
            </td>
          </tr>
          <tr>
            <td>Hourly (working)</td>
            <td>
              {config && price ? (
                <IncomePerWorkingHour config={config} price={price} />
              ) : null}
            </td>
          </tr>
          <tr>
            <td>Since this page was opened</td>
            <td>
              {config && price ? (
                <IncomeSincePageOpen config={config} price={price} />
              ) : null}
            </td>
          </tr>
        </table>
        <div style={{ marginTop: 20 }}>
          <button
            type="button"
            onClick={() => setIsEditingConfig(true)}
            style={{ width: "200px" }}
          >
            Configure
          </button>
        </div>
      </div>
    </Layout>
  );
};

export default VestyIndex;
